<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>

        <v-row justify="start mt-10">
            <v-col
                    cols="2"
                    v-for="title in titles"
                    v-bind:key="title.link"
                    class="viewCard"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <router-link :to="title.link">
                            <HoverCard :cardData="title"></HoverCard>
                            <!--<v-card
            :elevation="10"
            class="blue darken-1 text-center"
            height="100"
            width="200"
    >
        <v-list-item three-line>
            <v-list-item-content class="white--text">
                <v-row align="center" justify="center">
                    <span>{{title.name}}</span>
                </v-row>
                <v-divider></v-divider>
                <v-row align="center" justify="center">
                    <span>100</span>
                </v-row>
            </v-list-item-content>
        </v-list-item>
    </v-card>-->
                        </router-link>
                    </template>
                </v-tooltip>
            </v-col>
        </v-row>


    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
    export default {
        name: "Dashboard",
        watch: {},
        components: { HoverCard },
        computed: {
            items() {
                return [
                    {
                        text: this.$t('settings'),
                        disabled: true,
                        to: "/Hotel",
                        exact: true
                    }
                ]
            },
            titles() {
                return [
                    {
                        name: this.$t('ticket_category'),
                        link: "/PilgrimageService",
                        icon: "mdi-ticket-confirmation"
                    }
                ]
            }
        },
        data() {
            return {
                branchInformation: []

            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            formData() {

            },
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>